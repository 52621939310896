<template>
  <div>
    <kandidat-menu
      :kandidat="kandidat"
      :active-item="activeItem"
    />
    <b-card no-body>
      <b-card-body>
        <b-tabs
          fill
        >
          <b-tab
            :title="$t('Pregled')"
          >
            <section id="card-style-variation">
              <b-row>
                <!-- solid color -->
                <b-col
                  md="6"
                  xl="6"
                >
                  <b-card
                    bg-variant="transparent"
                    border-variant="secondary"
                  >
                    <b-card-title>
                      {{ $t('PodaciOKandidatu') }}
                    </b-card-title>
                    <b-card-text>
                      <b-list-group>
                        <b-list-group-item
                          class="flex-column align-items-start"
                        >
                          <div class="d-flex w-100 justify-content-between">
                            <h5 class="mb-1">
                              {{ $t('Kandidat') }}
                            </h5>
                          </div>
                          <b-card-text class="mb-1">
                            {{ kandidat.ime }} {{ kandidat.prezime }}
                          </b-card-text>
                        </b-list-group-item>
                        <b-list-group-item
                          class="flex-column align-items-start"
                        >
                          <div class="d-flex w-100 justify-content-between">
                            <h5 class="mb-1">
                              {{ $t('DatumUpisaURegistar') }}
                            </h5>
                          </div>
                          <b-card-text class="mb-1">
                            {{ getHumanDate(datum_upisa_u_registar) }}
                          </b-card-text>
                        </b-list-group-item>
                        <b-list-group-item
                          class="flex-column align-items-start"
                        >
                          <div class="d-flex w-100 justify-content-between">
                            <h5 class="mb-1">
                              {{ $t('Maloletan') }}
                            </h5>
                          </div>
                          <b-card-text class="mb-1">
                            {{ maloletan.text }}
                          </b-card-text>
                          <b-card-text
                            v-if="maloletan.value === 1"
                            class="mb-1"
                          >
                            {{ $t(roditelj.text) }} - {{ ime_roditelja }}, {{ $t('BrojLicneKarte') }} :  {{ brojLicneKarteRoditelja }}
                          </b-card-text>
                        </b-list-group-item>
                        <b-list-group-item
                          class="flex-column align-items-start"
                        >
                          <div class="d-flex w-100 justify-content-between">
                            <h5 class="mb-1">
                              {{ $t('Napomena') }}
                            </h5>
                          </div>
                          <b-card-text class="mb-1">
                            {{ napomena }}
                          </b-card-text>
                        </b-list-group-item>
                      </b-list-group>
                    </b-card-text>
                  </b-card>
                </b-col>
                <b-col
                  md="6"
                  xl="6"
                >
                  <b-card
                    bg-variant="transparent"
                    border-variant="secondary"
                  >
                    <b-card-title>
                      {{ $t('PodaciOPlacanju') }}
                    </b-card-title>
                    <b-card-text>
                      <b-list-group>
                        <b-list-group-item
                          class="flex-column align-items-start"
                        >
                          <div class="d-flex w-100 justify-content-between">
                            <h5 class="mb-1">
                              {{ $t('NacinPlacanja') }}
                            </h5>
                          </div>
                          <b-card-text class="mb-1">
                            {{ nacin_placanja.text }}
                          </b-card-text>
                        </b-list-group-item>
                        <b-list-group-item
                          class="flex-column align-items-start"
                        >
                          <div class="d-flex w-100 justify-content-between">
                            <h5 class="mb-1">
                              {{ $t('RokRealizacije') }}
                            </h5>
                          </div>
                          <b-card-text class="mb-1">
                            {{ getHumanDate(rok_realizacije) }}
                          </b-card-text>
                        </b-list-group-item>
                        <b-list-group-item
                          class="flex-column align-items-start"
                        >
                          <div class="d-flex w-100 justify-content-between">
                            <h5 class="mb-1">
                              {{ $t('UkupanIznos') }}
                            </h5>
                          </div>
                          <b-card-text class="mb-1">
                            {{ formatIznos(iznos) }}
                          </b-card-text>
                        </b-list-group-item>
                        <b-list-group-item
                          class="flex-column align-items-start"
                        >
                          <div class="d-flex w-100 justify-content-between">
                            <h5 class="mb-1">
                              {{ $t('BrojRata') }}
                            </h5>
                          </div>
                          <b-card-text class="mb-1">
                            {{ rate.length }}
                          </b-card-text>
                        </b-list-group-item>
                      </b-list-group>
                    </b-card-text>
                  </b-card>
                </b-col>
              </b-row>
            </section>
          </b-tab>
          <b-tab
            :title="$t('PodaciOPlacanju')"
          >
            <div class="text-right">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="primary"
                @click="snimiPodatke"
              >
                {{ $t('SnimiPodatke') }}
              </b-button>
            </div>
            <b-row>
              <b-col
                md="6"
                xl="6"
              >
                <b-form-group
                  :label="$t('NacinPlacanja')"
                  label-for="nacin_placanja"
                >
                  <v-select
                    v-model="nacin_placanja"
                    label="text"
                    :options="naciniPlacanja"
                    :placeholder="$t('IzaberiteNacin')"
                  />
                </b-form-group>
              </b-col>
              <b-col
                md="6"
                xl="6"
                class="mb-1"
              >
                <!-- basic -->
                <b-form-group
                  :label="$t('RokRealizacije')"
                  label-for="rok_realizacije"
                >
                  <b-form-datepicker
                    id="rok_realizacije"
                    v-model="rok_realizacije"
                    :placeholder="$t('RokRealizacije')"
                    :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                    :locale="$i18n.locale"
                    :hide-header="hideHeader"
                    today-button
                    reset-button
                    close-button
                    show-decade-nav
                    :label-today-button="$t('Danas')"
                    :label-reset-button="$t('Ponisti')"
                    :label-close-button="$t('Zatvori')"
                    :label-prev-year="$t('PrethodnaGodina')"
                    :label-prev-month="$t('PrethodniMesec')"
                    :label-next-year="$t('SledecaGodina')"
                    :label-next-month="$t('SledeciMesec')"
                    :label-current-month="$t('TekuciMesec')"
                    :label-prev-decade="$t('MinusDesetGodina')"
                    :label-next-decade="$t('PlusDesetGodina')"
                    label-help=""
                    no-flip
                  />
                </b-form-group>
              </b-col>
              <b-col
                md="6"
                xl="6"
              >
                <b-form-group
                  :label="$t('Maloletan')"
                  label-for="maloletan"
                >
                  <v-select
                    v-model="maloletan"
                    autocomplete="off"
                    label="text"
                    :options="maloletanOpcije"
                    :placeholder="$t('Maloletsan')"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                md="6"
                xl="6"
              >
                <b-form-group
                  :label="$t('ObrazacUgovora')"
                  label-for="obrazac"
                >
                  <v-select
                    v-model="obrazac_ugovora_id"
                    autocomplete="off"
                    label="naziv"
                    :options="obrasciUgovora"
                    :placeholder="$t('ObrazacUgovora')"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row
              v-if="maloletan.value == 1"
            >
              <b-col
                md="6"
                xl="6"
              >
                <b-form-group
                  :label="$t('Roditelj/Staratelj')"
                  label-for="roditelj"
                >
                  <v-select
                    v-model="roditelj"
                    autocomplete="off"
                    label="text"
                    :options="roditeljOpcije"
                    :placeholder="$t('Roditelj/Staratelj')"
                  />
                </b-form-group>
              </b-col>
              <b-col
                md="6"
                xl="6"
              >
                <b-form-group
                  :label="$t('ImeRoditelja/Staratelja')"
                  label-for="ime_roditelja"
                >
                  <b-form-input
                    v-model="ime_roditelja"
                    :placeholder="$t('ImeRoditelja/Staratelja')"
                  />
                </b-form-group>
              </b-col>
              <b-col
                md="6"
                xl="6"
              >
                <b-form-group
                  :label="$t('BrojLicneKarteRoditeljaStaratelja')"
                  label-for="broj_licne_karte_roditelja_staratelja"
                >
                  <b-form-input
                    v-model="brojLicneKarteRoditelja"
                    :placeholder="$t('BrojLicneKarteRoditeljaStaratelja')"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                md="12"
                xl="12"
              >
                <b-form-group
                  :label="$t('Napomena')"
                  label-for="napomena"
                >
                  <b-form-textarea
                    id="napomena"
                    v-model="napomena"
                    :placeholder="$t('Napomena')"
                    rows="3"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab
            no-body
            :title="$t('Cene')"
          >
            <div
              style="margin-bottom: 10px;"
              class="text-right"
            >
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="primary"
                @click="snimiCene"
              >
                {{ $t('SnimiCene') }}
              </b-button>
            </div>
            <b-table-simple
              hover
              small
              caption-top
              responsive
            >
              <colgroup><col><col></colgroup>
              <colgroup><col><col><col></colgroup>
              <colgroup><col><col></colgroup>
              <b-thead head-variant="light">
                <b-tr>
                  <b-th>{{ $t('RedniBroj') }}</b-th>
                  <b-th>{{ $t('Opis') }}</b-th>
                  <b-th>{{ $t('JedinicaMere') }}</b-th>
                  <b-th>{{ $t('CenaJedinice') }}</b-th>
                  <b-th>{{ $t('Kolicina') }}</b-th>
                  <b-th>{{ $t('Ukupno') }}</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-td>1.</b-td>
                  <b-td>
                    <span style="font-weight:bold;">{{ $t('TeorijskaObuka') }}</span>
                  </b-td>
                  <b-td>{{ $t('n/cas') }}</b-td>
                  <b-td>
                    <b-form-input
                      v-model="teorijska_obuka_cena"
                      size="sm"
                      :placeholder="$t('CenaJedinice')"
                    />
                  </b-td>
                  <b-td>
                    <b-form-input
                      v-model="teorijska_obuka_kolicina"
                      size="sm"
                      :placeholder="$t('Kolicina')"
                    />
                  </b-td>
                  <b-td>
                    <b-form-input
                      v-model="ukupnoTeorijskaObuka"
                      size="sm"
                      type="number"
                      :placeholder="$t('Ukupno')"
                    />
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td>2.</b-td>
                  <b-td>{{ $t('DodatnaTeorijskaObuka') }}</b-td>
                  <b-td>{{ $t('n/cas') }}</b-td>
                  <b-td>
                    <b-form-input
                      v-model="dodatna_teorijska_obuka_cena"
                      size="sm"
                      :placeholder="$t('CenaJedinice')"
                    />
                  </b-td>
                  <b-td>
                    <b-form-input
                      v-model="dodatna_teorijska_obuka_kolicina"
                      size="sm"
                      :placeholder="$t('Kolicina')"
                    />
                  </b-td>
                  <b-td>
                    <b-form-input
                      v-model="ukupno_dodatna_teorijska_obuka"
                      size="sm"
                      type="number"
                      :placeholder="$t('Ukupno')"
                    />
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td>3.</b-td>
                  <b-td>{{ $t('DopunskaTeorijskaObuka') }}</b-td>
                  <b-td>{{ $t('n/cas') }}</b-td>
                  <b-td>
                    <b-form-input
                      v-model="dopunska_teorijska_obuka_cena"
                      size="sm"
                      :placeholder="$t('CenaJedinice')"
                    />
                  </b-td>
                  <b-td>
                    <b-form-input
                      v-model="dopunska_teorijska_obuka_kolicina"
                      size="sm"
                      :placeholder="$t('Kolicina')"
                    />
                  </b-td>
                  <b-td>
                    <b-form-input
                      v-model="ukupno_dopunska_teorijska_obuka"
                      size="sm"
                      type="number"
                      :placeholder="$t('Ukupno')"
                    />
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td>4.</b-td>
                  <b-td>{{ $t('PolaganjeTeorijskogIspita') }}</b-td>
                  <b-td>{{ $t('kom') }}</b-td>
                  <b-td>
                    <b-form-input
                      v-model="polaganje_teorijskog_ispita_cena"
                      size="sm"
                      :placeholder="$t('CenaJedinice')"
                    />
                  </b-td>
                  <b-td>
                    <b-form-input
                      v-model="polaganje_teorijskog_ispita_kolicina"
                      size="sm"
                      :placeholder="$t('Kolicina')"
                    />
                  </b-td>
                  <b-td>
                    <b-form-input
                      v-model="ukupno_polaganje_teorijskog_ispita"
                      size="sm"
                      type="number"
                      :placeholder="$t('Ukupno')"
                    />
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td>5.</b-td>
                  <b-td>
                    <span style="font-weight:bold;">{{ $t('PrakticnaObuka') }}</span>
                  </b-td>
                  <b-td>{{ $t('n/cas') }}</b-td>
                  <b-td>
                    <b-form-input
                      v-model="prakticna_obuka_cena"
                      size="sm"
                      :placeholder="$t('CenaJedinice')"
                    />
                  </b-td>
                  <b-td>
                    <b-form-input
                      v-model="prakticna_obuka_kolicina"
                      size="sm"
                      :placeholder="$t('Kolicina')"
                    />
                  </b-td>
                  <b-td>
                    <b-form-input
                      v-model="ukupno_prakticna_obuka"
                      size="sm"
                      type="number"
                      :placeholder="$t('Ukupno')"
                    />
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td>6.</b-td>
                  <b-td>{{ $t('DodatnaPrakticnaObuka') }}</b-td>
                  <b-td>{{ $t('n/cas') }}</b-td>
                  <b-td>
                    <b-form-input
                      v-model="dodatna_prakticna_obuka_cena"
                      size="sm"
                      :placeholder="$t('CenaJedinice')"
                    />
                  </b-td>
                  <b-td>
                    <b-form-input
                      v-model="dodatna_prakticna_obuka_kolicina"
                      size="sm"
                      :placeholder="$t('Kolicina')"
                    />
                  </b-td>
                  <b-td>
                    <b-form-input
                      v-model="ukupno_dodatna_prakticna_obuka"
                      size="sm"
                      type="number"
                      :placeholder="$t('Ukupno')"
                    />
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td>7.</b-td>
                  <b-td>{{ $t('DopunskaPrakticnaObuka') }}</b-td>
                  <b-td>{{ $t('n/cas') }}</b-td>
                  <b-td>
                    <b-form-input
                      v-model="dopunska_prakticna_obuka_cena"
                      size="sm"
                      :placeholder="$t('CenaJedinice')"
                    />
                  </b-td>
                  <b-td>
                    <b-form-input
                      v-model="dopunska_prakticna_obuka_kolicina"
                      size="sm"
                      :placeholder="$t('Kolicina')"
                    />
                  </b-td>
                  <b-td>
                    <b-form-input
                      v-model="ukupno_dopunska_prakticna_obuka"
                      size="sm"
                      type="number"
                      :placeholder="$t('Ukupno')"
                    />
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td>8.</b-td>
                  <b-td>{{ $t('PolaganjePrakticnogIspita') }}</b-td>
                  <b-td>{{ $t('kom') }}</b-td>
                  <b-td>
                    <b-form-input
                      v-model="polaganje_prakticnog_ispita_cena"
                      size="sm"
                      :placeholder="$t('CenaJedinice')"
                    />
                  </b-td>
                  <b-td>
                    <b-form-input
                      v-model="polaganje_prakticnog_ispita_kolicina"
                      size="sm"
                      :placeholder="$t('Kolicina')"
                    />
                  </b-td>
                  <b-td>
                    <b-form-input
                      v-model="ukupno_polaganje_prakticnog_ispita"
                      size="sm"
                      type="number"
                      :placeholder="$t('Ukupno')"
                    />
                  </b-td>
                </b-tr>
              </b-tbody>
              <b-tfoot>
                <b-tr>
                  <b-td
                    colspan="6"
                    variant="secondary"
                    class="text-right"
                  >
                    {{ $t('Svega') }}:
                    <b>{{ iznos }}</b>
                  </b-td>
                </b-tr>
              </b-tfoot>
            </b-table-simple>
          </b-tab>
          <b-tab
            v-if="nacin_placanja.value === 'rate'"
            lazy
            :title="$t('Rate')"
          >
            <div
              style="margin-bottom: 10px;"
              class="text-right"
            >
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="primary"
                @click="snimiRate"
              >
                {{ $t('SnimiRate') }}
              </b-button>
            </div>
            <b-card class="border mb-0">
              <b-form
                ref="form"
                :style="{height: trHeight}"
                class="repeater-form"
                @submit.prevent="dodajRatu"
              >
                <!-- Row Loop -->
                <b-row
                  v-for="(rata, index) in rate"
                  :id="rata.id"
                  :key="rata.id"
                  ref="row"
                >
                  <b-col
                    md="4"
                  >
                    <b-form-group
                      :label="$t('DatumRate')"
                    >
                      <b-form-datepicker
                        v-model="rata.datum"
                        :placeholder="$t('Datum')"
                        class="mb-1"
                        no-flip
                        :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                        :locale="$i18n.locale"
                        :hide-header="hideHeader"
                        today-button
                        reset-button
                        close-button
                        show-decade-nav
                        :label-today-button="$t('Danas')"
                        :label-reset-button="$t('Ponisti')"
                        :label-close-button="$t('Zatvori')"
                        :label-prev-year="$t('PrethodnaGodina')"
                        :label-prev-month="$t('PrethodniMesec')"
                        :label-next-year="$t('SledecaGodina')"
                        :label-next-month="$t('SledeciMesec')"
                        :label-current-month="$t('TekuciMesec')"
                        :label-prev-decade="$t('MinusDesetGodina')"
                        :label-next-decade="$t('PlusDesetGodina')"
                        label-help=""
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    md="2"
                  >
                    <b-form-group
                      :label="$t('Iznos')"
                    >
                      <b-form-input
                        v-model="rata.iznos"
                        name="iznosRate"
                        type="number"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    lg="2"
                    md="3"
                    class="mb-50"
                  >
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="mt-0 mt-md-2"
                      @click="removeItem(index)"
                    >
                      <feather-icon
                        icon="XIcon"
                        class="mr-25"
                      />
                      <span>{{ $t('Obrisi') }}</span>
                    </b-button>
                  </b-col>
                  <b-col
                    cols="12"
                  >
                    <hr>
                  </b-col>
                </b-row>
              </b-form>
              <b-row>
                <b-col
                  md="3"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    :disabled="nacin_placanja.value !== 'rate'"
                    @click="dodajRatu"
                  >
                    <feather-icon
                      icon="PlusIcon"
                      class="mr-25"
                    />
                    <span>{{ $t('DodajRatu') }}</span>
                  </b-button>
                </b-col>
                <b-col
                  v-if="rate.length === 1 && nacin_placanja.value === 'rate'"
                  md="2"
                >
                  {{ $t('IliPodeliNa') }}
                </b-col>
                <b-col
                  v-if="rate.length === 1 && nacin_placanja.value === 'rate'"
                  md="6"
                >
                  <b-input-group>
                    <b-form-input
                      v-model="broj_rata_za_podelu"
                      :placeholder="$t('BrojRata')"
                    />
                    <b-input-group-append>
                      <b-button
                        variant="outline-primary"
                        @click="podeliNaRate"
                      >
                        {{ $t('Podeli') }}
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-col>
              </b-row>
            </b-card>
          </b-tab>
        </b-tabs>
      </b-card-body>
    </b-card>
    <b-modal
      id="modal-danger"
      ok-only
      ok-variant="danger"
      :ok-title="$t('Obrisi')"
      modal-class="modal-danger"
      centered
      :title="$t('Upozorenje')"
    >
      <b-card-text>
        {{ $t('IznosRataIIznosUgovoraSeNeSlazu') }}
      </b-card-text>
      <b-card-text>
        {{ $t('ZbirIznosaRataJe:') }} {{ formatIznos(rate.reduce((a, b) => +a + +b.iznos, 0)) }}
      </b-card-text>
      <b-card-text>
        {{ $t('IznosUgovoraJe:') }} {{ formatIznos(iznos) }}
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import {
  BButton,
  BCardBody,
  BCard,
  BRow,
  BCol,
  BFormDatepicker,
  BForm,
  BFormGroup,
  BFormTextarea,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BTfoot,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BTabs,
  BTab,
  BCardTitle,
  BCardText,
  BListGroup,
  BListGroupItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { heightTransition } from '@core/mixins/ui/transition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
import KandidatMenu from '../kandidati/KandidatMenu.vue'

export default {
  components: {
    'kandidat-menu': KandidatMenu,
    BButton,
    BCard,
    BCardBody,
    BForm,
    BRow,
    BCol,
    BFormDatepicker,
    BFormGroup,
    BFormTextarea,
    vSelect,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BTabs,
    BTab,
    BCardTitle,
    BCardText,
    BListGroup,
    BListGroupItem,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      id: 0,
      kandidat_id: 0,
      activeItem: 'ugovori',
      kandidat: {},
      datum_upisa_u_registar: '',
      rate: [],
      broj_rata_za_podelu: 0,
      nextTodoId: 2,
      hideHeader: true,
      rok_realizacije: '',
      maloletan: [],
      roditelj: [],
      nacin_placanja: [],
      napomena: '',
      naciniPlacanja: [
        { value: 'unapred', text: this.$i18n.t('Unapred') },
        { value: 'rate', text: this.$i18n.t('NaRate') },
        { value: 'do_kraja', text: this.$i18n.t('DoKraja') },
      ],
      maloletanOpcije: [
        { value: '1', text: this.$i18n.t('Maloletan') },
        { value: '0', text: this.$i18n.t('Punoletan') },
      ],
      roditeljOpcije: [
        { value: '1', text: this.$i18n.t('Roditelj') },
        { value: '0', text: this.$i18n.t('Staratelj') },
      ],
      ime_roditelja: '',
      brojLicneKarteRoditelja: '',
      teorijska_obuka_cena: 0,
      teorijska_obuka_kolicina: 0,
      dodatna_teorijska_obuka_cena: 0,
      dodatna_teorijska_obuka_kolicina: 0,
      dopunska_teorijska_obuka_cena: 0,
      dopunska_teorijska_obuka_kolicina: 0,
      polaganje_teorijskog_ispita_cena: 0,
      polaganje_teorijskog_ispita_kolicina: 0,

      prakticna_obuka_cena: 0,
      prakticna_obuka_kolicina: 0,
      dodatna_prakticna_obuka_cena: 0,
      dodatna_prakticna_obuka_kolicina: 0,
      dopunska_prakticna_obuka_cena: 0,
      dopunska_prakticna_obuka_kolicina: 0,
      polaganje_prakticnog_ispita_cena: 0,
      polaganje_prakticnog_ispita_kolicina: 0,
      iznosRate: 0,
      datumRate: '',
      obrasciUgovora: [],
      obrazac_ugovora_id: '',
    }
  },
  computed: {
    ukupnoTeorijskaObuka() {
      return this.teorijska_obuka_cena * this.teorijska_obuka_kolicina
    },
    ukupno_dodatna_teorijska_obuka() {
      return (
        this.dodatna_teorijska_obuka_cena * this.dodatna_teorijska_obuka_kolicina
      )
    },
    ukupno_dopunska_teorijska_obuka() {
      return (
        this.dopunska_teorijska_obuka_cena * this.dopunska_teorijska_obuka_kolicina
      )
    },
    ukupno_polaganje_teorijskog_ispita() {
      return (
        this.polaganje_teorijskog_ispita_cena * this.polaganje_teorijskog_ispita_kolicina
      )
    },
    ukupno_prakticna_obuka() {
      return this.prakticna_obuka_cena * this.prakticna_obuka_kolicina
    },
    ukupno_dodatna_prakticna_obuka() {
      return (
        this.dodatna_prakticna_obuka_cena * this.dodatna_prakticna_obuka_kolicina
      )
    },
    ukupno_dopunska_prakticna_obuka() {
      return (
        this.dopunska_prakticna_obuka_cena * this.dopunska_prakticna_obuka_kolicina
      )
    },
    ukupno_polaganje_prakticnog_ispita() {
      return (
        this.polaganje_prakticnog_ispita_cena * this.polaganje_prakticnog_ispita_kolicina
      )
    },
    iznos() {
      return (
        this.teorijska_obuka_cena * this.teorijska_obuka_kolicina + this.dodatna_teorijska_obuka_cena * this.dodatna_teorijska_obuka_kolicina + this.dopunska_teorijska_obuka_cena * this.dopunska_teorijska_obuka_kolicina + this.polaganje_teorijskog_ispita_cena * this.polaganje_teorijskog_ispita_kolicina + this.prakticna_obuka_cena * this.prakticna_obuka_kolicina + this.dodatna_prakticna_obuka_cena * this.dodatna_prakticna_obuka_kolicina + this.dopunska_prakticna_obuka_cena * this.dopunska_prakticna_obuka_kolicina + this.polaganje_prakticnog_ispita_cena * this.polaganje_prakticnog_ispita_kolicina
      )
    },
  },
  mounted() {
    this.$http.get('/autoskole-administratori/ugovor-obrasci-ugovora').then(response => {
      this.obrasciUgovora = response.data
    })
    this.$http
      .get(`/autoskole-administratori/ugovori/${this.$route.params.id}/edit`)
      .then(response => {
        const ugovorData = response.data
        this.teorijska_obuka_cena = ugovorData.teorijska_obuka_cena
        this.teorijska_obuka_kolicina = ugovorData.teorijska_obuka_kolicina
        this.dodatna_teorijska_obuka_cena = ugovorData.dodatna_teorijska_obuka_cena
        this.dodatna_teorijska_obuka_kolicina = ugovorData.dodatna_teorijska_obuka_kolicina
        this.dopunska_teorijska_obuka_cena = ugovorData.dopunska_teorijska_obuka_cena
        this.dopunska_teorijska_obuka_kolicina = ugovorData.dopunska_teorijska_obuka_kolicina
        this.polaganje_teorijskog_ispita_cena = ugovorData.polaganje_teorijskog_ispita_cena
        this.polaganje_teorijskog_ispita_kolicina = ugovorData.polaganje_teorijskog_ispita_kolicina
        this.prakticna_obuka_cena = ugovorData.prakticna_obuka_cena
        this.prakticna_obuka_kolicina = ugovorData.prakticna_obuka_kolicina
        this.dodatna_prakticna_obuka_cena = ugovorData.dodatna_prakticna_obuka_cena
        this.dodatna_prakticna_obuka_kolicina = ugovorData.dodatna_prakticna_obuka_kolicina
        this.dopunska_prakticna_obuka_cena = ugovorData.dopunska_prakticna_obuka_cena
        this.dopunska_prakticna_obuka_kolicina = ugovorData.dopunska_prakticna_obuka_kolicina
        this.polaganje_prakticnog_ispita_cena = ugovorData.polaganje_prakticnog_ispita_cena
        this.polaganje_prakticnog_ispita_kolicina = ugovorData.polaganje_prakticnog_ispita_kolicina

        const [postojeciNacinPlacanja] = this.naciniPlacanja.filter(e => e.value === response.data.nacin_placanja)
        this.nacin_placanja = postojeciNacinPlacanja
        this.rok_realizacije = ugovorData.rok_realizacije
        this.napomena = ugovorData.napomena
        const [postojeciMaloletan] = this.maloletanOpcije.filter(e => parseInt(e.value, 10) === response.data.maloletan)
        this.maloletan = postojeciMaloletan
        const [postojeciRoditelj] = this.roditeljOpcije.filter(e => parseInt(e.value, 10) === response.data.roditelj)
        this.roditelj = postojeciRoditelj
        this.ime_roditelja = ugovorData.ime_roditelja
        this.brojLicneKarteRoditelja = ugovorData.brojLicneKarteRoditelja
        this.kandidat_id = ugovorData.kandidat_id
        this.obrazac_ugovora_id = this.obrasciUgovora.filter(e => e.id === response.data.obrazac.id)
        this.$http
          .get(`/autoskole-administratori/kandidati/${this.kandidat_id}`)
          .then(responseKandidat => {
            this.kandidat = responseKandidat.data.kandidat
            this.datum_upisa_u_registar = responseKandidat.data.kandidat.datum_upisa_u_registar
          })
        ugovorData.rateData.forEach(element => {
          this.rate.push({
            datum: element.datum,
            iznos: element.iznos,
            id: element.id,
          })
        })
      })
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    snimiPodatke() {
      const data = {
        nacin_placanja: this.nacin_placanja.value,
        rok_realizacije: this.rok_realizacije,
        napomena: this.napomena,
        maloletan: this.maloletan.value,
        roditelj: this.roditelj.value,
        ime_roditelja: this.ime_roditelja,
        br_lk_roditelja: this.brojLicneKarteRoditelja,
        obrazac_ugovora_id: this.obrazac_ugovora_id.id,
      }
      this.$http
        .put(`/autoskole-administratori/ugovori/${this.$route.params.id}`, {
          data,
        })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$i18n.t('PodaciSuSnimljeni'),
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
    },
    snimiCene() {
      const data = {
        cena_teor_obuke: this.teorijska_obuka_cena,
        kol_teor_obuke: this.teorijska_obuka_kolicina,
        cena_dod_teor_obuka: this.dodatna_teorijska_obuka_cena,
        kol_dod_teor_obuka: this.dodatna_teorijska_obuka_kolicina,
        cena_dop_teor_obuka: this.dopunska_teorijska_obuka_cena,
        kol_dop_teor_obuka: this.dopunska_teorijska_obuka_kolicina,
        cena_pol_teor_isp: this.polaganje_teorijskog_ispita_cena,
        kol_pol_teor_isp: this.polaganje_teorijskog_ispita_kolicina,
        cena_prak_obuke: this.prakticna_obuka_cena,
        kol_prak_obuke: this.prakticna_obuka_kolicina,
        cena_dod_prak_obuke: this.dodatna_prakticna_obuka_cena,
        kol_dod_prak_obuke: this.dodatna_prakticna_obuka_kolicina,
        cena_dop_prak_obuke: this.dopunska_prakticna_obuka_cena,
        kol_dop_prak_obuke: this.dopunska_prakticna_obuka_kolicina,
        cena_pol_prak_isp: this.polaganje_prakticnog_ispita_cena,
        kol_pol_prak_isp: this.polaganje_prakticnog_ispita_kolicina,
        iznos: this.iznos,
      }
      this.$http
        .put(`/autoskole-administratori/ugovori/${this.$route.params.id}`, {
          data,
        })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$i18n.t('CeneSuSnimljene'),
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
    },
    snimiRate() {
      const sumaRata = this.rate.reduce((a, b) => +a + +b.iznos, 0)
      if (this.nacin_placanja.value === 'rate' && sumaRata !== this.iznos) {
        this.$bvModal.show('modal-danger')
      } else {
        const data = this.rate
        this.$http
          .put(`/autoskole-administratori/ugovor-rate/${this.$route.params.id}`, {
            data,
          })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$i18n.t('RateSuSnimljene'),
                icon: 'EditIcon',
                variant: 'success',
              },
            })
          })
      }
    },
    podeliNaRate() {
      const iznosZaPodelu = this.iznos - this.rate[0].iznos
      const iznosRateZaDodavanje = iznosZaPodelu / this.broj_rata_za_podelu

      for (let i = 0; i < this.broj_rata_za_podelu; i += 1) {
        this.rate.push({
          datum: this.addOneMonth(this.rate[i].datum),
          iznos: iznosRateZaDodavanje,
        })
      }
    },
    formatIznos(value) {
      const val = (value / 1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    getHumanDate(date) {
      return date !== ''
        ? moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY.')
        : ''
    },
    addOneMonth(date) {
      if (date !== '') {
        return moment(date, 'YYYY-MM-DD')
          .add(1, 'months')
          .format('YYYY-MM-DD')
      }

      return moment()
        .add(1, 'months')
        .format('YYYY-MM-DD')
    },
    formSubmitted() {
      const ugovorData = {
        rateData: this.rate,
      }
      this.$http
        .put(`/autoskole-administratori/ugovori/${this.$route.params.id}`, {
          ugovorData,
        })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Form Submitted',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
    },
    dodajRatu() {
      this.rate.push({
        datum: '',
        iznos: 0,
      })
    },
    removeItem(index) {
      this.rate.splice(index, 1)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: visible;
  transition: .35s height;
}
</style>
